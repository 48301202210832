import { Col, Divider, message } from "antd";
import { useTranslation } from "react-i18next";
import LinesEllipsis from "react-lines-ellipsis";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Row } from "components/Grid";
import { Link } from "components/Link";
import { Card } from "components/Card";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { Text } from "components/Typography";
import { getConfig, useConfig, useCustomer, useLazyApiCall } from "state";
import { apiServices, formatAccount, isMobile, userFullName } from "helpers";
import { PersonalCard } from "components/PersonalCard";
import { EmailForm } from "./containers/EmailForm";
import { PasswordForm } from "./containers/PasswordForm";
import { refreshCustomer } from "state/effects/refreshCustomer";
import { Loader } from "../../../components/Loader";

export const PersonalInfo: FC = () => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState({ email: false, password: false });
  const [limitLines, setLimit] = useState(true);
  const [dispatchCalled, setDispatchCalled] = useState(false);
  const [loadedFired, setLoadedFired] = useState(false);
  const customer = useCustomer();
  const config = useConfig();
  const isVE = config.connectorType === "VE";
  const siteTheme = config.amsSite?.siteTheme;
  const acceptMsg = siteTheme?.labels?.["label.mealplan.optin.successful"];
  const declineMsg = siteTheme?.labels?.["label.mealplan.optout.successful"];
  const accountTitleMsg = siteTheme?.labels?.["label.auth.account.title"];
  const accountTitleMsgLabel = isVE ? accountTitleMsg ?? t("CUSTOMER_CODE") : t("MY_EMPLOYEE_ID");

  const toggleEditMode = (type: "email" | "password") => () => {
    setEditMode((s) => ({ ...s, [type]: !s[type] }));
  };

  const [changeOpt, setChangeOpt] = useState(false);

  const enableWaiver = config.amsSite && config.amsSite.enableWaiver;
  const version = config.amsSite?.siteWaiver.version;
  const cVersion = customer?.waiverVersion;
  const cAcceptance = (customer?.waiverAcceptance || "").toUpperCase() === "ACCEPT";

  const optIn = cAcceptance && version?.toLowerCase() === cVersion?.toLowerCase();

  const { dispatch, isLoading, data, error, ok } = useLazyApiCall(
    apiServices.updateProfile(),
    refreshCustomer
  );

  const setIn = useCallback(
    (value: boolean) => {
      dispatch(
        {
          body: {
            waiverAcceptance: value ? "ACCEPT" : "DECLINE",
            waiverVersion: version,
          },
        },
        1
      );
      setDispatchCalled(true);
    },
    [dispatch, version]
  );

  const handleAccept = useCallback(() => {
    setIn(true);
    setChangeOpt(false);
    setLoadedFired(false);
  }, [setIn]);

  const handleDecline = useCallback(() => {
    message.warning(t("PAYROLL_DEDUCT_NOT_ACTIVE_YET"));
    setIn(false);
    setChangeOpt(false);
    setLoadedFired(false);
  }, [setIn, t]);

  const handleOptOut = useCallback(() => {
    setIn(false);
    setLoadedFired(false);
  }, [setIn]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (dispatchCalled) {
      if (error) {
        message.error(error);
      }

      if (ok && data) {
        if (String(data.waiverAcceptance).toUpperCase() === "DECLINE") {
          message.success(declineMsg ? declineMsg : t("PAYROLL_DEDUCT_DEACTIVATED"));
        } else if (String(data.waiverAcceptance).toUpperCase() === "ACCEPT") {
          message.success(acceptMsg ? acceptMsg : t("PAYROLL_DEDUCT_ACTIVATED"));
        }
      }

      setDispatchCalled(false);
    }
  }, [isLoading, ok, error, data, t, dispatchCalled, declineMsg, acceptMsg]);

  const labels = config.amsSite?.siteTheme?.labels || undefined;

  const optInText = optIn
    ? (labels && labels["label.mealplan.optout.description"]) ?? t("ID_CARD_REVOKE_MESSAGE")
    : (labels && labels["label.mealplan.optin.description"]) ?? t("ID_CARD_AUTHORIZE_MESSAGE");

  useEffect(() => {
    if (document.querySelector("iframe") && !loadedFired) {
      // @ts-ignore the null check is present above
      document.querySelector("iframe").onload = function () {
        // @ts-ignore 'this' refers to the iframe
        const iframeBody = this.contentDocument;
        if (iframeBody === null) setLoadedFired(true);
      };
    }
  });

  return (
    <>
      <Card className="mb-4" padding="large">
        <PersonalCard
          accountTitleMsgLabel={accountTitleMsgLabel ?? t("MY_EMPLOYEE_ID")}
          user={customer}
        />
      </Card>

      <Card className="mb-4" padding="large">
        <Row>
          <Col md={24} xs={24}>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <Text strong>
                {t("YOUR_EMAIL")}
                {":"}
              </Text>
              {!editMode.email && (
                <Button type="link" onClick={!editMode.email ? toggleEditMode("email") : undefined}>
                  {t("CHANGE")}
                </Button>
              )}
            </div>
            <Text className={`w-100 py-2`}>{customer?.email}</Text>
            {editMode.email && (
              <div className="mt-3">
                <EmailForm onCancel={toggleEditMode("email")} onSuccess={toggleEditMode("email")} />
              </div>
            )}
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col md={24} xs={24}>
            {editMode.password ? (
              <PasswordForm
                onCancel={toggleEditMode("password")}
                onSuccess={toggleEditMode("password")}
              />
            ) : (
              <div className="d-flex flex-row align-items-center justify-content-between">
                <Text strong>
                  {t("PASSWORD")}
                  {":"}
                </Text>
                <Button
                  type="link"
                  onClick={!editMode.password ? toggleEditMode("password") : undefined}
                >
                  {t("CHANGE")}
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Card>

      {enableWaiver && (
        <Card className="mb-4" padding="large">
          <Row>
            <Col xs={24}>
              <Text strong className="d-block mb-3">
                {optIn
                  ? (labels && labels["label.mealplan.title.deactivate"]) ??
                    t("PAYROLL_DEDUCT_OPTOUT")
                  : (labels && labels["label.mealplan.title.activate"]) ??
                    t("PAYROLL_DEDUCT_OPTIN")}
                {":"}
              </Text>
              <Text color="disabled" line="large">
                <LinesEllipsis
                  trimRight
                  basedOn="letters"
                  className={limitLines ? "d-block" : "d-inline"}
                  ellipsis={
                    <>
                      ...{" "}
                      <Link
                        to="#opt-in"
                        onClick={(e) => {
                          e.preventDefault();
                          setLimit(false);
                        }}
                      >
                        {t("READ_MORE")}
                      </Link>
                    </>
                  }
                  maxLine={limitLines ? 2 : 100}
                  text={optInText}
                />
              </Text>
              {!limitLines && (
                <>
                  {" "}
                  <Link
                    color="text"
                    to="#opt-in"
                    onClick={(e) => {
                      e.preventDefault();
                      setLimit(true);
                    }}
                  >
                    {t("READ_LESS")}
                  </Link>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12} xs={24}>
              <Button
                block
                className="d-block mt-4"
                disabled={isLoading}
                type="primary"
                onClick={() => (optIn ? handleOptOut() : setChangeOpt(true))}
              >
                {optIn
                  ? (labels && labels["label.mealplan.button.deactivate"]) ?? t("OPT_OUT")
                  : (labels && labels["label.mealplan.button.activate"]) ?? t("OPT_IN")}
              </Button>
            </Col>
          </Row>
        </Card>
      )}

      <Modal
        actions={[
          <Button
            disabled={isLoading}
            key="cancel"
            type="secondary"
            onClick={() => {
              setChangeOpt(false);
              setLoadedFired(false);
            }}
          >
            {t("CANCEL")}
          </Button>,
          <Button disabled={isLoading} key="decline" type="danger" onClick={handleDecline}>
            {t("DECLINE")}
          </Button>,
          <Button disabled={isLoading} key="accept" type="primary" onClick={handleAccept}>
            {t("ACCEPT")}
          </Button>,
        ]}
        isOpen={changeOpt}
        size="large"
        title={(labels && labels["label.mealplan.title.subject"]) ?? t("PAYROLL_DEDUCT_AGREEMENT")}
        onRequestClose={() => {
          setChangeOpt(false);
          setLoadedFired(false);
        }}
      >
        <>
          <Row className="mb-4">
            <Col md={8} sm={12} xs={24}>
              <Text className="mr-2" color="disabled">
                {t("NAME")}:
              </Text>
              <Text strong>{userFullName(customer)}</Text>
            </Col>
            <Col md={14} sm={12} xs={24}>
              <Text className="mr-2" color="disabled">
                {accountTitleMsgLabel}:
              </Text>
              <Text strong>{formatAccount(customer?.customerCode || "")}</Text>
            </Col>
          </Row>
          <Loader size={"large"} spinning={!loadedFired && isLoading}>
            <iframe
              src={changeOpt ? getConfig().amsSite?.siteWaiver?.waiverUrl : ""}
              // eslint-disable-next-line no-inline-styles/no-inline-styles
              style={{ border: "none", minHeight: isMobile() ? 400 : 500, width: "100%" }}
              title="DGPR"
            />
          </Loader>
        </>
      </Modal>
    </>
  );
};
