import { Api } from "api";
import { getConfig } from "state";
import { RequestOptions } from "state";

export const DEFAULT_PAGE_SIZE = 30;
export const EXPORT_PAGE_SIZE = 1000;

/**
 * List of api services and request creators
 */
export const apiServices = {
  /**
   * Auto Load account with old or new payment method
   * @param accountId
   * @param body
   */
  autoload: (accountId: string, body?: Api["AutoloadDetailsPut"]) => ({
    body,
    method: "PUT",
    path: `/v1/user/accounts/${accountId}/autoload`,
  }),

  /**
   * Request logged user profile
   * @param {string} token
   */
  customer: (token?: string) => {
    return {
      headers: token ? { Authorization: `Bearer ${token}` } : undefined,
      method: "GET",
      path: "/v1/user#get-customer",
    };
  },

  /**
   * Disable Auto Load account with old or new payment method
   * @param accountId
   * @param body
   */
  disableAutoload: (accountId: ID) => ({
    method: "DELETE",
    path: `/v1/user/accounts/${accountId}/autoload`,
  }),

  /**
   * email confirmation resend via token
   * @param id
   */
  emailResend: (id: string) => ({
    body: { userId: id },
    method: "POST",
    path: "/v1/auth/resend-verification",
  }),

  /**
   * email verification via token
   * @param token
   */
  emailVerification: (token: string) => ({
    method: "GET",
    path: "/v1/auth/verifications",
    query: { token: token },
  }),

  /**
   * Request new password
   * @param {string} customerCode
   * @param {string} email
   */
  forgot: (customerCode?: string, email?: string) => {
    const body = {
      customerCode,
      email,
      siteName: getConfig().name,
    };
    return { auth: false, body, method: "POST", path: "/v1/auth/forgot-password" };
  },

  /**
   * Sign-Up new guest customer
   * @param {Object} body - sign-up form
   */
  guestSignUp: (body?: Omit<Api["GuestUser"], "siteName">) => ({
    body: { siteName: getConfig().name, ...(body || {}) },
    method: "POST",
    path: "/v1/auth/sign-up",
  }),

  /**
   * Manual Load account with old or new payment method
   * @param accountId
   * @param body
   */
  load: (
    accountId: string,
    body?: Api["LoadAccountPostNewCard"] | Api["LoadAccountPostSavedCard"]
  ) => ({ body, method: "POST", path: `/v1/user/accounts/${accountId}` }),

  /**
   * Log-in with credentials
   * @param {string} _customerCode
   * @param {string} password
   */
  login: (_customerCode?: string, password?: string): RequestOptions => {
    const body: Api["LoginRequest"] = {
      grant_type: "PASSWORD",
      password,
      siteName: getConfig().name,
    };
    return {
      auth: false,
      body,
      method: "POST",
      path: "/v1/auth/token",
    };
  },

  /**
   * Request payment provider credentials
   */
  paymentIframe: () => ({ method: "GET", path: "/v1/current-site/payment/iframe" }),

  /**
   * Fetch all point buckets for current site
   */
  pointBuckets: () => ({ method: "GET", path: "/v1/current-site/points/buckets" }),

  /**
   * Refresh access token by using refresh - TODO: use cookie...
   * @param {string} token
   */
  refreshToken: (token: string) => ({
    auth: false,
    body: { grant_type: "REFRESH_TOKEN", refresh_token: token, siteName: getConfig().name },
    method: "POST",
    path: "/v1/auth/token",
  }),

  /**
   * Remove current customer payment
   * @param {ID} id
   */
  removePayment: (id?: ID) => ({
    method: "DELETE",
    path: `/v1/user/saved-payments/${id}`,
  }),

  /**
   * Save notification preferences
   * @param {ID} primaryAccountId - TODO: specify how to get "primary account ID"
   */
  saveNotifications: (
    primaryAccountId: ID,
    autoloadFailure?: boolean,
    lowBalance?: boolean,
    loadAccount?: boolean
  ) => {
    return {
      body: {
        autoloadFailureNotification: !!autoloadFailure,
        loadAccountNotification: !!loadAccount,
        lowBalanceNotification: !!lowBalance,
      },
      method: "PUT",
      path: `/v1/user/notifications/${primaryAccountId}`,
    };
  },

  /**
   * Save card payment method
   */
  savePayment: (type: "PAY_CONEX" | "FREEDOM_PAY" | "NONE" = "NONE") => ({
    body: { type },
    method: "POST",
    path: "/v1/user/saved-payments",
  }),

  /**
   * Change password for current user
   * @param {string} originalPassword
   * @param {string} newPassword
   */
  setPassword: (originalPassword?: string, newPassword?: string) => ({
    body: { newPassword, originalPassword },
    method: "PUT",
    path: "/v1/user/password",
  }),

  /**
   * Sign-Up new Customer
   * @param {Object} body - sign-up form
   */
  signUp: (body?: Omit<Api["EmployeeUser"], "siteName">) => ({
    body: { siteName: getConfig().name, ...(body || {}) },
    method: "POST",
    path: "/v1/auth/sign-up",
  }),

  /**
   * Request site config
   * @param {string} site - site / brand name
   */
  site: (site?: string) => ({
    auth: false,
    method: "GET",
    path: "/v1/site",
    query: { name: site || getConfig().name },
  }),

  /**
   * Transaction detail
   * @param {ID} id
   */
  transaction: (id: ID, storeId?: string) => ({
    method: "GET",
    path: `/v1/user/transactions/${id}`,
    query: { storeId },
  }),
  /**
   * Transaction list aka Activity history
   * @param {Array<ID>} accountIds - request transaction history for specific accounts
   * @param size
   * @param page
   * @param startTime
   * @param endTime
   */
  transactions: (
    accountIds: string,
    size?: number,
    page?: number,
    startTime?: string,
    endTime?: string
  ) => ({
    method: "GET",
    path: "/v1/user/transactions",
    query: { accountIds, endTime: endTime, page: page, size: size, startTime: startTime },
  }),
  /**
   * Update current user profile
   */
  updateProfile: (changes?: Api["CustomerPatch"]) => ({
    body: changes || {},
    method: "PATCH",
    path: "/v1/user#updateProfile",
  }),
} as const;

Object.freeze(apiServices);
